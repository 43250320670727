body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos customizados para react-draft-wysiwyg */
.wrapperClass {
  position: "relative";
  border: 1px solid #ABAFB3;
  border-radius: 10px;
}
.editorClass {
  padding: 1rem;
  border-top: 1px solid #ABAFB3;
  background-color: #F5F9F6;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Poppins';
  font-weight: 'regular';
  font-size: 14px;
}
.toolbarClass {
  /* border: 1px solid red !important; */
  position: "sticky";
  padding: 1rem;
  top: "5rem";
  z-index: 9;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.swal2-modal {
  border-radius: 8px;
}

.custom-actions-swal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.custom-btn-cancel-swal {
  flex: 1;
  border: 1px solid #cacaca;
  background: white;
  border-radius: 8px;
  min-width: initial;
  color: #cacaca;
  padding: 5px 15px;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  margin-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  cursor: pointer;
}

.custom-btn-confirm-swal {
  cursor: pointer;
  flex: 1;
  border: none;
  background: linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%);
  border-radius: 8px;
  min-width: initial;
  color: white;
  padding: 5px 15px;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  margin-left: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.custom-title-swal {
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.custom-text-swal {
  /* text-align: justify !important; */
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 400;
  font-size: .9rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
}

.custom-title-warning-swal {
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 9.5rem;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: url('./assets/barra_peligro.png');
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  display: flex !important;
  align-items: end;
  color: white;
  font-weight: 700;
  font-size: 1.3rem
}

.custom-title-success-swal {
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 9.5rem;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: url('./assets/barra_exito.png');
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  display: flex !important;
  align-items: end;
  color: white;
  font-weight: 700;
  font-size: 1.3rem
}
.custom-title-info-swal {
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 9.5rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  width: 100%;
  border-top-right-radius: '8px';
  background-image: url('./assets/barra_info.png');
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  display: flex !important;
  align-items: end;
  color: white;
  font-weight: 700;
  font-size: 1.3rem;
  overflow-y: hidden;
}
.custom-title-error-swal {
  margin: 0;
  font-family: Barlow,Ubuntu,Arial;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  text-align: center;
  padding: 16px 24px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  height: 9.5rem;
  width: 100%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: url('./assets/barra_error.png');
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  display: flex !important;
  align-items: end;
  color: white;
  font-weight: 700;
  font-size: 1.3rem
}

.swal2-container {
  z-index: 1350;
}