/* NOTE: Estilos para contenido draftjs renderizado */

/* .preview img {
  width: 100% !important;
} */
.preview {
  word-break: break-word;
}

.preview p {
  word-break: break-word;
}

.preview p a {
  word-break: break-all;
}

.preview ul li > a {
  word-break: break-all;
}

@media only screen and (max-width: 600px) {
  .preview {
    font-size: 10.5pt;
    word-break: break-word;
  }

  .preview img {
    width: 100% !important;
  }

  .preview p {
    margin-left: 0;
    margin-right: 0;
    padding-left: 5px;
    padding-right: 8px;
    text-align: justify;
  }

  .preview p {
    word-break: break-word;
  }

  .preview p a {
    word-break: break-all;
  }

  .preview ul li > a {
    word-break: break-all;
  }
}